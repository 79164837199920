<!--
 * @Author: 张亚欣
 * @Date: 2021-07-05 16:19:20
 * @LastEditTime: 2021-09-01 14:53:05
 * @LastEditors: 张亚欣
 * @Description: 自定义页面容器
 * @FilePath: \lightning-web\src\views\commonObjects\custome.vue
-->
<template>
  <div class="custome">
    <iframe :src="src" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</template>

<script>
import Bus from "@/mixin/bus.js";
import VueCookies from "vue-cookies";

export default {
  name: "Custome",
  data() {
    return {
      src: "",
    };
  },
  created() {},
  mounted() {
    this.src = `${
      window.location.origin
    }/assets/customize/static/${VueCookies.get("customePageUrl")}`;

    // 监听customePageUrl变化
    Bus.$on("changeCustomePageUrl", this.changeCustomePageUrl);
  },
  beforeDestroy() {
    Bus.$off("changeCustomePageUrl", this.changeCustomePageUrl);
  },
  methods: {
    changeCustomePageUrl(customePageUrl) {
      this.src = `${window.location.origin}/assets/customize/static/${customePageUrl}`;
    }
  },
};
</script>

<style scoped lang="scss">
.custome {
  height: 100%;
}
</style>
